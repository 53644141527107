import {useCallback, useEffect, useState} from 'react';
import config from '../config';
import {useWeb3React} from '@web3-react/core'
import {getUsdtBalanceNumber} from "../utils/formatBalance";
import BigNumber from "bignumber.js";

const  useEthBalance = (account: string) => {
  const [balance, setBalance] = useState(0);
  const {library} = useWeb3React();
  const fetchBalance = useCallback(async () => {
    if (!account) {
      setBalance(0);
      return balance;
    }
    try{
      const balance2 = (await library.getBalance(account));
      const balanceFormated = getUsdtBalanceNumber(new BigNumber(balance2.toString()));
      setBalance(balanceFormated);
    }catch (e) {
      setBalance(0);
      console.error(e);
    }
  }, [library]);

  useEffect(() => {
    fetchBalance().catch((err) =>
      console.error(`Failed to fetch token balance: ${err.stack}`),
    );
    let refreshInterval = setInterval(fetchBalance, config.refreshInterval);
    return () => clearInterval(refreshInterval);
  }, [library]);

  return balance;
};

export default useEthBalance;



