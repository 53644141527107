import Swap from "./scy/Swap";
import Home from "./scy/Home";
import Blindbox from "./scy/Blindbox";
import Economic from "./scy/Economic";

export default {
  Home,
  Blindbox,
  Economic,
  Swap
};
