import React, {ReactNode, useState} from 'react'
import {Layout} from 'antd'
import MobileHeader from './components/MobileHeader'
import Footer from '../Footer'
import Header from '../Header'
import './Frame.less'
import {RouteComponentProps, withRouter} from 'react-router-dom'

const { Content } = Layout

type PageProps = RouteComponentProps<any> & {
  children?: ReactNode
}

function Frame(props: PageProps) {
  const [collapsed, setCollapsed] = useState(true)

  const mobileHeadClick = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Layout className="frame">
      {/* <MobileHeader onClick={mobileHeadClick} props={props} /> */}
      <Header/>
      <Content className="Main_Content">{props.children}</Content>
      {/* <Footer/> */}
    </Layout>
  )
}

export default withRouter(Frame)
