const menus = {
  // 菜单相关路由
  menus: [
    {
      key: "/swap",
      component: "Swap",
    },
    {
      key: "/blindbox",
      component: "Blindbox",
    },
    {
      key: "/economic",
      component: "Economic",
    },
    {
      key: "/",
      component: "Home",
    },
    {
      key: "/:code",
      component: "Home",
    },
  ],
  lists: [],
};

export default menus;
