import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Progress, Tooltip, message, Modal, Input, Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

import card from "../../../assets/img/scy/icon-card.png";
import tooltip from "../../../assets/img/scy/tooltip.png";
import bnb from "../../../assets/img/scy/bnb.png";
import rocket from "../../../assets/img/scy/icon-rocket.png";
import share from "../../../assets/img/scy/icon-share.png";
import share_yes from "../../../assets/img/scy/icon-share_yes.png";
import close from "../../../assets/img/scy/close.png";

import "./home.less";
import { getUsdtBalanceNumber } from "../../../utils/formatBalance";
import { BigNumber } from "bignumber.js";
import {
  AIRDROP,
  DECIMAL_BNB,
  ETH,
  PXW,
  PXWBUILD,
  SERVER_URL,
  SWAP_ROUTER,
  WBNB,
} from "../../../constants";
import useInterval from "../../../hooks";
import {
  useAirdrop,
  usePxwBuild,
  usePxwToken,
  usePxwTokenLib,
  useSwapRoter,
} from "../../../hooks/useContract";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import Web3 from "web3";
import useEthBalance from "../../../hooks/useEthBalance";
const Home = (props) => {
  const { t } = useTranslation();
  const { account, library } = useWeb3React();
  const pxwTokenLib = usePxwTokenLib();
  const pxwToken = usePxwToken();
  const airdrop = useAirdrop();
  const pxwBuild = usePxwBuild();
  const swapRoter = useSwapRoter();
  const pxwEthBalance = useEthBalance(PXW);

  const [myInviter, setMyInviter] = useState("");
  const myInviterChange = ({ target: { value } }) => {
    setMyInviter(value);
  };
  const [myBindInviter, setMyBindInviter] = useState(null);

  useEffect(() => {
    const scrapId = props.match.params.code;
    console.info("scrapId=" + scrapId);
    if (scrapId !== undefined) {
      setMyInviter(scrapId);
    }
  }, [props, setMyInviter]);

  const [coinageCount, setCoinageCount] = useState(0);
  const [totalCoinage, setTotalCoinage] = useState(150000);
  const [percent, setPercent] = useState(0);

  const [totalCombustion, setTotalCombustion] = useState(0);
  const [totalCollection, setTotalCollection] = useState(0);
  const [remainingUnclaimed, setRemainingUnclaimed] = useState(0);

  const [currentCombustion, setCurrentCombustion] = useState(0);
  const [currentlyCollection, setCurrentlyCollection] = useState(0);
  const [currentClaim, setCurrentClaim] = useState(0);
  const [flammable, setFlammable] = useState(0);
  const [burningNum, setBurningNum] = useState(0);

  const burningNumChange = ({ target: { value } }) => {
    setBurningNum(value);
  };

  const doPreAmount = useCallback(async () => {
    if (parseFloat(pxwEthBalance.toString()) > 0) {
      const changeTotal = new BigNumber(pxwEthBalance.toString())
        .multipliedBy(DECIMAL_BNB)
        .toString();
      const path = [WBNB, PXW];
      const amountOut = await swapRoter.getAmountsOut(changeTotal, path);
      const toTotal = getUsdtBalanceNumber(
        new BigNumber(amountOut[1].toString())
      );
      console.info("amountOutShow=" + toTotal.toString());
      setFlammable(toTotal);
    }
  }, [
    pxwToken,
    setFlammable,
    WBNB,
    PXW,
    DECIMAL_BNB,
    swapRoter,
    pxwEthBalance,
  ]);

  const [totalReward, setTotalReward] = useState(0);
  const [directNum, setDirectNum] = useState(0);
  const [indirectNum, setIndirectNum] = useState(0);

  const [showDirect, setShowDirect] = useState(true);
  // 邀请列表
  const [directInvite, setDirectInvite] = useState([""]);
  const [indirectInvite, setIndirectInvite] = useState([""]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 提示文案
  const tooltipText = t("TIP_PRICE");
  const tooltipTextTwo = t("tooltipText");

  const onCopy = () => {
    message.success(t("Replicating Success"));
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    console.log("绑定");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isTipOpen, setIsTipOpen] = useState(false);
  const showTip = () => {
    setIsTipOpen(true);
  };
  const hideTip = () => {
    setIsTipOpen(false);
  };

  const APPROVE_AMOUNT = ethers.constants.MaxUint256;
  const [pxwBalance, setPxwBalance] = useState(0);
  const [pxwAllowance, setPxwAllowance] = useState(0);

  const fetchLastTime2 = useCallback(async () => {
    try {
      // console.info('account=' + account);
      const pxwAllowance = getUsdtBalanceNumber(
        new BigNumber(
          (await pxwTokenLib.allowance(account, PXWBUILD)).toString()
        )
      );
      // console.info('pxwAllowance=' + pxwAllowance);
      setPxwAllowance(pxwAllowance);
      const pxwBalance = getUsdtBalanceNumber(
        new BigNumber((await pxwTokenLib.balanceOf(account)).toString())
      );
      setPxwBalance(pxwBalance);

      const coinageCount = parseInt((await airdrop.totalDroped()).toString());
      setCoinageCount(coinageCount);
      setPercent((coinageCount * 100) / totalCoinage);

      const buildInfo = await pxwBuild.getBuildInfo();
      console.info("buildInfo=");
      console.info(buildInfo);
      setTotalCombustion(
        getUsdtBalanceNumber(new BigNumber(buildInfo[0].toString()), 9)
      );
      setTotalCollection(
        getUsdtBalanceNumber(new BigNumber(buildInfo[1].toString()), 9)
      );
      setRemainingUnclaimed(
        getUsdtBalanceNumber(new BigNumber(buildInfo[2].toString()), 9)
      );

      const userInfo = await pxwBuild.userInfo(account);
      console.info("userInfo");
      console.info(userInfo);
      setCurrentCombustion(
        getUsdtBalanceNumber(new BigNumber(userInfo[0].toString()))
      );
      setCurrentlyCollection(
        getUsdtBalanceNumber(new BigNumber(userInfo[1].toString()))
      );
      setCurrentClaim(
        getUsdtBalanceNumber(new BigNumber(userInfo[2].toString()))
      );

      const invitationListInfo = await pxwBuild.getInvitationListInfo(account);
      console.info(invitationListInfo);
      const hasBindInviter = invitationListInfo[0].toString().toLowerCase();
      console.info("hasBindInviter==>" + hasBindInviter);
      setMyBindInviter(hasBindInviter === ETH ? null : hasBindInviter);
      setTotalReward(
        getUsdtBalanceNumber(new BigNumber(invitationListInfo[1].toString()))
      );

      setDirectNum(invitationListInfo[2].length);
      setIndirectNum(invitationListInfo[3].length);
      setDirectInvite(invitationListInfo[2]);
      setIndirectInvite(invitationListInfo[3]);

      doPreAmount();
    } catch (e) {
      console.error(e);
    }
  }, [
    account,
    pxwToken,
    pxwTokenLib,
    airdrop,
    setPxwAllowance,
    setPxwBalance,
    setCoinageCount,
    setPercent,
    totalCoinage,
    pxwBuild,
    setTotalCombustion,
    setTotalCollection,
    setRemainingUnclaimed,
    setCurrentCombustion,
    setCurrentlyCollection,
    setCurrentClaim,
    ETH,
    setTotalReward,
    setDirectNum,
    setIndirectNum,
    setDirectInvite,
    setIndirectInvite,
  ]);
  useInterval(fetchLastTime2, 2000);

  const doMintToken = useCallback(async () => {
    const mintCostBnb = new BigNumber("0.002")
      .multipliedBy(DECIMAL_BNB)
      .toString();
    const web3 = new Web3(library.provider);
    message.success(t("sending"));
    const gasPrice = await web3.eth.getGasPrice();
    await web3.eth
      .sendTransaction({
        from: account,
        to: AIRDROP,
        value: mintCostBnb,
        maxFeePerGas: ethers.utils.parseUnits("3", "gwei"),
        maxPriorityFeePerGas: gasPrice,
      })
      .then(async (responseA) => {
        console.info(responseA);
        message.success(t("success"));
      })
      .catch((error) => {
        message.error(t("error"));
        console.log(error);
      });
  }, [account, pxwToken, library, AIRDROP, DECIMAL_BNB]);

  const doReceiveRewards = useCallback(async () => {
    handleCancel();
    message.success(t("sending"));
    await pxwBuild
      .receiveRewards(account)
      .then(async (responseA) => {
        console.info(responseA);
        message.success(t("success"));
      })
      .catch((error) => {
        message.error(t("error"));
        console.log(error);
      });
  }, [account, pxwBuild]);

  const doBurnToHolder = useCallback(async () => {
    const inviter = myBindInviter ? myBindInviter : myInviter;
    console.info(inviter);
    if (!inviter) {
      return message.error(t("inviter address mistake"));
    }
    if (inviter.length !== 42) {
      return message.error(t("inviter address mistake"));
    }
    if (pxwBalance < burningNum) {
      return message.error(t("your PXW token balance is not enough"));
    }
    const burningNumValue = new BigNumber(burningNum)
      .multipliedBy(DECIMAL_BNB)
      .toString();
    message.success(t("sending"));
    await pxwBuild
      .burnToHolder(burningNumValue, inviter)
      .then(async (responseA) => {
        console.info(responseA);
        message.success(t("success"));
      })
      .catch((error) => {
        message.error(t("error"));
        console.log(error);
      });
  }, [
    account,
    pxwBuild,
    myInviter,
    myBindInviter,
    burningNum,
    PXWBUILD,
    DECIMAL_BNB,
    pxwBalance,
    pxwAllowance,
  ]);

  const doSetInvitation = useCallback(async () => {
    console.info(myInviter);
    if (!myInviter) {
      return message.error(t("inviter address mistake"));
    }
    if (myInviter.length !== 42) {
      return message.error(t("inviter address mistake"));
    }
    message.success(t("sending"));
    await pxwBuild
      .setInvitation(myInviter)
      .then(async (responseA) => {
        console.info(responseA);
        message.success(t("success"));
      })
      .catch((error) => {
        message.error(t("error"));
        console.log(error);
      });
  }, [account, pxwBuild, myInviter]);

  return (
    <div className="home">
      <div className="cast_on_outwell_box">
        <div className="outwell_left">
          <div className="icon">
            <div className="icon_top">
              <img src={card} alt="" />
            </div>
            <div className="icon_bom"></div>
          </div>
          <div className="balance">
            <div className="balance_text">{t("User A token balance")}</div>
            <div className="balance_num">{pxwBalance}</div>
            <div className="outwell_con_m">
              <div className="text">
                {t("Current Coinage Count")}：{coinageCount}
              </div>
              <div className="text">
                {t("Total Coinage Times")}：{totalCoinage}
              </div>
              <Progress
                percent={percent}
                strokeColor="#6D8CFE"
                trailColor="#2A215A"
                showInfo={false}
                className="outwell_progress"
              />
            </div>
          </div>
        </div>
        <div className="outwell_con">
          <div className="text">
            {t("Current Coinage Count")}：{coinageCount}
          </div>
          <div className="text">
            {t("Total Coinage Times")}：{totalCoinage}
          </div>
          <Progress
            percent={percent}
            strokeColor="#6D8CFE"
            trailColor="#2A215A"
            showInfo={false}
            className="outwell_progress"
          />
        </div>
        <div className="outwell_right">
          <div className="outwell_right_content">
            {/*<Button onClick={doMintToken} className="outwell_btn">
              {t("Casting A token")}
            </Button>*/}
            <Tooltip title={tooltipText}>
              <img src={tooltip} alt="" />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="line"></div>
      <div className="be_worth">
        <div className="be_worth_box">
          <div className="be_worth_box_icon">
            <img src={bnb} alt="" />
          </div>
          <div className="be_worth_box_info">
            <div className="text">{t("Total combustion")}</div>
            <div className="num">{totalCombustion}</div>
          </div>
        </div>
        <div className="be_worth_box">
          <div className="be_worth_box_icon">
            <img src={bnb} alt="" />
          </div>
          <div className="be_worth_box_info">
            <div className="text">{t("Total collection")}</div>
            <div className="num">{totalCollection}</div>
          </div>
        </div>
        <div className="be_worth_box">
          <div className="be_worth_box_icon">
            <img src={bnb} alt="" />
          </div>
          <div className="be_worth_box_info">
            <div className="text">{t("Remaining unclaimed")}</div>
            <div className="num">{remainingUnclaimed}</div>
          </div>
        </div>
      </div>
      <div className="my_info">
        <div className="title">{t("My Information")}</div>
        <div className="info_box">
          <div className="be_worth_box">
            <div className="be_worth_box_icon">
              <img src={bnb} alt="" />
            </div>
            <div className="be_worth_box_info">
              <div className="text">{t("Total combustion")}</div>
              <div className="num">{currentCombustion}</div>
            </div>
          </div>
          <div className="be_worth_box">
            <div className="be_worth_box_icon">
              <img src={bnb} alt="" />
            </div>
            <div className="be_worth_box_info">
              <div className="text">
                {t("Currently available for collection")}
              </div>
              <div className="num">{currentlyCollection}</div>
            </div>
          </div>
          <div className="be_worth_box">
            <div className="be_worth_box_icon">
              <img src={bnb} alt="" />
            </div>
            <div className="be_worth_box_info">
              <div className="text">{t("Current claim")}</div>
              <div className="num">{currentClaim}</div>
            </div>
          </div>
        </div>
        <div className="btn_box">
          <div className="jiantou_left"></div>
          <Button className="btn" onClick={showModal}>
            <span>{t("Claim rewards")}</span>
          </Button>
          <div className="jiantou_right"></div>
        </div>
      </div>
      <div className="combustion">
        <div className="combustion_top">
          <div className="be_worth_box">
            <div className="be_worth_box_icon">
              <img src={rocket} alt="" />
            </div>
            <div className="be_worth_box_info">
              <div className="text">{t("Burning quantity")}</div>
              <div className="num">{pxwEthBalance}</div>
            </div>
          </div>
          <div className="be_worth_box">
            <div className="be_worth_box_icon">
              <img src={rocket} alt="" />
            </div>
            <div className="be_worth_box_info">
              <div className="text">{t("Flammable")}</div>
              <div className="num">{flammable}</div>
            </div>
          </div>
        </div>

        <div className="combustion_input">
          <div className="tit">{t("Inviter's address")}</div>
          {/*<div className="text">{inviterAddress}</div>*/}
          <Input
            className="text"
            placeholder={t("input inviter")}
            bordered={false}
            disabled={myBindInviter !== null}
            value={myBindInviter ? myBindInviter : myInviter}
            onChange={myInviterChange}
          ></Input>
        </div>

        <div className="combustion_input">
          <div className="tit">{t("Burning quantitynum")}</div>
          <Input
            className="text"
            placeholder={t("input amount")}
            bordered={false}
            value={burningNum}
            onChange={burningNumChange}
          ></Input>
        </div>
        <div className="btn_box">
          <div className="jiantou_left"></div>
          <Button onClick={doBurnToHolder} className="btn">
            {t("combust")}
          </Button>
          <div className="jiantou_right"></div>
        </div>

        <Tooltip title={tooltipTextTwo}>
          <div className="prompt"></div>
        </Tooltip>
      </div>
      <div className="bind_invitation">
        <div className="totle_invitation">
          <div className="title">{t("Invitation total reward")}</div>
          <div className="num">{totalReward}</div>
        </div>
        <div className="combustion_top_bg"></div>
        <div className="input_info">
          <div className="tit">{t("My inviter")}</div>
          <Input
            className="text"
            placeholder={t("input inviter")}
            bordered={false}
            disabled={myBindInviter !== null}
            value={myBindInviter ? myBindInviter : myInviter}
            onChange={myInviterChange}
          ></Input>

          {myBindInviter ? (
            <div />
          ) : (
            <Button className="btn" onClick={doSetInvitation}>
              {t("Bind inviter")}
            </Button>
          )}
        </div>
        <div className="input_info">
          <div className="tit">{t("My invitation link")}</div>
          <div className="text">
            {!account ? "" : SERVER_URL + "" + account}
          </div>
          <CopyToClipboard
            text={!account ? "" : SERVER_URL + "" + account}
            onCopy={onCopy}
          >
            <Button className="btn">{t("copy link")}</Button>
          </CopyToClipboard>
        </div>
        <div className="bind_invitation_top">
          <div
            className="be_worth_box"
            onClick={() => {
              setShowDirect(true);
            }}
          >
            <img
              className="be_worth_box_icon"
              src={showDirect ? share_yes : share}
              alt=""
            />
            <div className="be_worth_box_info">
              <div className="text">{t("Number of direct referrals")}</div>
              <div className="num">{directNum}</div>
            </div>
          </div>
          <div
            className="be_worth_box"
            onClick={() => {
              setShowDirect(false);
            }}
          >
            <img
              className="be_worth_box_icon"
              src={!showDirect ? share_yes : share}
              alt=""
            />
            <div className="be_worth_box_info">
              <div className="text">{t("Indirect referrals")}</div>
              <div className="num">{indirectNum} </div>
            </div>
          </div>
        </div>
        <div className="invite_list">
          <div className="invite_list_box">
            {(showDirect ? directInvite : indirectInvite).map((item, i) => {
              return (
                <div key={i} className="invite_list_item">
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* 绑定邀请人弹窗 */}
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered={true}
        className="my_inviter_modal"
      >
        <div className="top">
          <div className="title">{t("Claim rewards")}</div>
          <img
            src={close}
            alt=""
            className="clone_icon"
            onClick={handleCancel}
          />
        </div>
        <div className="input_info">
          {t("OUT_COIN", { amount: currentlyCollection })}
        </div>

        <Button className="binding" onClick={doReceiveRewards}>
          {t("Claim rewards")}
        </Button>
      </Modal>
    </div>
  );
};
export default Home;
