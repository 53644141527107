import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import "./Swap.less";
import { useWeb3React } from "@web3-react/core";
import {
  usePxwToken,
  usePxwTokenLib,
  useSwapRoter,
} from "../../../hooks/useContract";
import { Input, message, Progress, Slider, Switch } from "antd";
import { BigNumber } from "bignumber.js";
import { getUsdtBalanceNumber } from "../../../utils/formatBalance";
import { ethers } from "ethers";
import useInterval from "../../../hooks";
import LOGO_IMG from "../../../assets/img/logo.png";
import BNB_IMG from "../../../assets/img/bnb.png";
import PXW_IMG from "../../../assets/img/logo.png";
import SWAP_IMG from "../../../assets/img/swap/swap.png";
import { DECIMAL_BNB, PXW, SWAP_ROUTER, WBNB } from "../../../constants";
import useEthBalance from "../../../hooks/useEthBalance";
import useEthBySwap from "../../../hooks/useEthBySwap";

const Invite = (props) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const pxwTokenLib = usePxwTokenLib();
  const pxwToken = usePxwToken();
  const swapRoter = useSwapRoter();
  const ethBalance = useEthBalance(account);
  // console.info('ethBalance2=' + ethBalance);
  const { doBuySwap } = useEthBySwap();

  const [slippage, setSlippage] = useState(2);
  const [bnbAmount, setBnbAmount] = useState(0);
  const bnbAmountChange = ({ target: { value } }) => {
    setBnbAmount(value);
    if (isBuy) {
      doPreAmount(value, true);
    }
  };

  const [scyAmount, setScyAmount] = useState(0);
  const scyAmountChange = ({ target: { value } }) => {
    setScyAmount(value);
    doPreAmount(value, false);
  };

  const [isBuy, setIsBuy] = useState(true);
  const isBuyChange = ({ target: { value } }) => {
    setIsBuy(!isBuy);
  };

  const bnbMax = () => {
    setBnbAmount(ethBalance);
    doPreAmount(ethBalance, true);
  };
  const scyMax = () => {
    setScyAmount(pxwBalance);
    doPreAmount(pxwBalance, false);
  };
  const APPROVE_AMOUNT = ethers.constants.MaxUint256;
  const [pxwBalance, setPxwBalance] = useState(0);
  const [pxwAllowance, setPxwAllowance] = useState(0);

  const fetchLastTime2 = useCallback(async () => {
    try {
      // console.info('account=' + account);
      const pxwAllowance = getUsdtBalanceNumber(
        new BigNumber(
          (await pxwTokenLib.allowance(account, SWAP_ROUTER)).toString()
        )
      );
      // console.info('pxwAllowance=' + pxwAllowance);
      setPxwAllowance(pxwAllowance);
      const pxwBalance = getUsdtBalanceNumber(
        new BigNumber((await pxwTokenLib.balanceOf(account)).toString())
      );
      setPxwBalance(pxwBalance);
    } catch (e) {
      console.error(e);
    }
  }, [
    account,
    pxwToken,
    pxwTokenLib,
    setPxwBalance,
    setPxwAllowance,
    SWAP_ROUTER,
  ]);
  useInterval(fetchLastTime2, 2000);

  const doSwap = useCallback(
    async (bySplit) => {
      if (!account) {
        return message.error(t("please connect the wollet first"));
      }
      const buyAmount = isBuy ? bnbAmount : scyAmount;
      if (buyAmount <= 0) {
        return message.error(t("the min amount is zero"));
      }
      const amountIn = new BigNumber(buyAmount.toString())
        .multipliedBy(DECIMAL_BNB)
        .toString();

      try {
        const path = isBuy ? [WBNB, PXW] : [PXW, WBNB];

        const milliseconds = Date.now();
        const deadline = Math.floor(milliseconds / 1000) + 100;
        console.info("deadline=" + deadline);

        // swapExactTokensForTokensSupportingFeeOnTransferTokens token换bnb用这个接口
        // swapExactETHForTokensSupportingFeeOnTransferTokens bnb换token用这个接口
        if (isBuy) {
          if (ethBalance < buyAmount) {
            return message.error(t("your BNB token balance is not enough"));
          }
          const amountOutMin = new BigNumber(scyAmount.toString())
            .multipliedBy(DECIMAL_BNB)
            .multipliedBy(100 - slippage)
            .div(100)
            .toString();
          console.info("amountOutMin=" + amountOutMin);
          console.info("account=" + account);
          console.info("path=");
          console.info(path);
          message.success(t("sending"));
          await doBuySwap(amountIn, amountOutMin, path, deadline.toString())
            .then(async (responseA) => {
              console.info(responseA);
              message.success(t("SUCCESS"));
            })
            .catch((error) => {
              message.error(t("ERROR"));
              console.log(error);
            });
        } else {
          if (pxwBalance < buyAmount) {
            return message.error(t("your PXW token balance is not enough"));
          }
          if (pxwAllowance < buyAmount) {
            pxwToken
              .approve(SWAP_ROUTER, APPROVE_AMOUNT)
              .then(async (responseA) => {
                console.info(responseA);
                message.success(t("SUCCESS"));
              })
              .catch((error) => {
                message.error(t("ERROR"));
                console.log(error);
              });
            return;
          }
          const amountOutMin = new BigNumber(bnbAmount.toString())
            .multipliedBy(DECIMAL_BNB)
            .multipliedBy(100 - slippage)
            .div(100)
            .toString();
          message.success(t("sending"));
          await swapRoter
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(
              amountIn,
              amountOutMin,
              path,
              account,
              deadline
            )
            .then(async (responseA) => {
              console.info(responseA);
              message.success(t("SUCCESS"));
            })
            .catch((error) => {
              message.error(t("ERROR"));
              console.log(error);
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      account,
      APPROVE_AMOUNT,
      DECIMAL_BNB,
      isBuy,
      bnbAmount,
      scyAmount,
      ethBalance,
      pxwToken,
      swapRoter,
      SWAP_ROUTER,
      WBNB,
      PXW,
      doBuySwap,
    ]
  );

  const doPreAmount = useCallback(
    async (changeAmount, bnbChange) => {
      if (parseFloat(changeAmount.toString()) > 0) {
        const changeTotal = new BigNumber(changeAmount.toString())
          .multipliedBy(DECIMAL_BNB)
          .toString();
        const path = bnbChange ? [WBNB, PXW] : [PXW, WBNB];
        const amountOut = await swapRoter.getAmountsOut(changeTotal, path);
        console.info("amountOut=" + amountOut[1].toString());
        const toTotal = getUsdtBalanceNumber(
          new BigNumber(amountOut[1].toString())
        );
        console.info("amountOutShow=" + toTotal.toString());
        if (bnbChange) {
          setScyAmount(toTotal);
        } else {
          setBnbAmount(toTotal);
        }
      }
    },
    [
      isBuy,
      pxwToken,
      scyAmount,
      bnbAmount,
      setScyAmount,
      setBnbAmount,
      WBNB,
      PXW,
      swapRoter,
    ]
  );

  return (
    <div className="in_page">
      <div className="log-row">
        <img className="item-img" src={LOGO_IMG} />
        <div className="logo-name">PXW Swap</div>
      </div>

      <div className="swap_area">
        <div className="dashboard_data">
          <div className="flexStart dashboard_data_item">
            <div>
              <img className="item-img" src={isBuy ? BNB_IMG : PXW_IMG} />
              <span>{isBuy ? "BNB" : "PXW"}:</span>
            </div>
            <div className="u-line-1 u-line-1-add">
              <span onClick={isBuy ? bnbMax : scyMax}>MAX</span>
            </div>
          </div>
          <div className="input-area">
            <div className="u-input" style={{ padding: "6px 9px" }}>
              <Input
                className="buy_inout"
                placeholder={t("INPUT_JOIN_AMOUNT")}
                bordered={false}
                value={isBuy ? bnbAmount : scyAmount}
                onChange={isBuy ? bnbAmountChange : scyAmountChange}
              ></Input>
            </div>
            <div className="USDT">
              <span>
                {t("SWAP_BALANCE")}: {isBuy ? ethBalance : pxwBalance}
              </span>
            </div>
          </div>
        </div>

        <div className="switch-area">
          <img className="switch-img" onClick={isBuyChange} src={SWAP_IMG} />
        </div>

        <div className="dashboard_data">
          <div className="flexStart dashboard_data_item">
            <div>
              <img className="item-img" src={isBuy ? PXW_IMG : BNB_IMG} />
              <span>{isBuy ? "PXW" : "BNB"}:</span>
            </div>
            <div className="u-line-1 u-line-1-add">
              <span onClick={isBuy ? scyMax : bnbMax}>MAX</span>
            </div>
          </div>

          <div className="input-area">
            <div className="u-input" style={{ padding: "6px 9px" }}>
              <Input
                className="buy_inout"
                placeholder={t("INPUT_JOIN_AMOUNT")}
                bordered={false}
                value={isBuy ? scyAmount : bnbAmount}
                onChange={isBuy ? scyAmountChange : bnbAmountChange}
              ></Input>
            </div>
            <div className="USDT">
              <span>
                {t("SWAP_BALANCE")}: {isBuy ? pxwBalance : ethBalance}
              </span>
            </div>
          </div>
        </div>

        <div className="s-huadian">
          <div>
            <span>{t("SWAP_HD")}:</span>
          </div>
          <div className="u-line-1 u-line-1-add">
            <span>{slippage}%</span>
          </div>
        </div>

        <Slider
          min={2}
          max={50}
          step={1}
          defaultValue={2}
          className="outwell_progress"
          onChange={setSlippage}
          value={slippage}
        />

        <div className="handle-row">
          <Button className="comm_btn" onClick={() => doSwap(true)}>
            {(isBuy ? false : pxwAllowance < scyAmount)
              ? t("APPROVE")
              : t("SWAP_LJQY")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Invite;
