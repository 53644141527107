import {Contract} from '@ethersproject/contracts'
import {useMemo} from 'react'
import {useActiveWeb3React} from './index'
import {getContract} from '../utils/utils'
import ERC20_ABI from '../constants/abis/erc20.json';
import ERC720_ABI from '../constants/abis/erc720.json';
import ROUTER_ABI from '../constants/abis/Swap.json';
import {SWAP_ROUTER, PXW, AIRDROP, BIND_BOX, PXWBUILD} from '../constants'
import ERC20 from "../constants/ERC20";
import PXW_ABI from "../constants/abis/PXW.json";
import AIR_DROP_ABI from "../constants/abis/AirDrop.json";
import BIND_BOX_ABI from "../constants/abis/BindBox.json";
import PXW_BUILD_ABI from "../constants/abis/PXWBuild.json";

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}


export function useTokenContract(tokenAddress?: string, is20?: boolean): Contract | null {
  return useContract(tokenAddress, is20 ? ERC20_ABI : ERC720_ABI)
}

export function usePxwTokenLib() : ERC20 | null {
  const { library } = useActiveWeb3React();
  return new ERC20(PXW, library, 'AAA');
}

export function usePxwToken() : Contract | null {
  return useContract(PXW, PXW_ABI);
}

export function useAirdrop() : Contract | null {
  return useContract(AIRDROP, AIR_DROP_ABI);
}

export function useBindBox() : Contract | null {
  return useContract(BIND_BOX, BIND_BOX_ABI);
}

export function usePxwBuild() : Contract | null {
  return useContract(PXWBUILD, PXW_BUILD_ABI);
}

export function useSwapRoter() : Contract | null {
  return useContract(SWAP_ROUTER, ROUTER_ABI);
}

