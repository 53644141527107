import React from "react";
import ReactDOM from "react-dom";
import ThemeProvider, { GlobalStyle } from "./Theme";
import LocalStorageContextProvider from "./contexts/LocalStorage";
import TokenDataContextProvider from "./contexts/TokenData";
import GlobalDataContextProvider from "./contexts/GlobalData";
import PairDataContextProvider from "./contexts/PairData";
import ApplicationContextProvider from "./contexts/Application";
import UserContextProvider from "./contexts/User";
import App from "./App";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import { NetworkContextName } from "./constants";
import getLibrary from "./utils/getLibrary";
import { Provider } from "react-redux";
import store from "./state";
import Updaters from "./state/Updaters";
import "./i18n";

// eslint-disable-next-line react/prop-types
function ContextProviders({ children }) {
  return (
    <LocalStorageContextProvider>
      <ApplicationContextProvider>
        <TokenDataContextProvider>
          <GlobalDataContextProvider>
            <PairDataContextProvider>
              <UserContextProvider>{children}</UserContextProvider>
            </PairDataContextProvider>
          </GlobalDataContextProvider>
        </TokenDataContextProvider>
      </ApplicationContextProvider>
    </LocalStorageContextProvider>
  );
}

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

ReactDOM.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <ContextProviders>
          <ThemeProvider>
            <>
              <GlobalStyle />
              <Updaters />
              <App />
            </>
          </ThemeProvider>
        </ContextProviders>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </Provider>,
  document.getElementById("root")
);
