import React from "react";
import "./BoxItem.less";
import boxImg from "../../../assets/img/game/box.png";

interface BoxProps {
  amount: string;
  onClick?: (index: any) => void;
}

const BoxItem: React.FC<BoxProps> = ({amount, onClick,}) => {
  return (
      <div className="box_item">
        <img className="box_img" src={boxImg} alt="icon" />
        <div className="box_name"> {amount} </div>
      </div>
  );
};

export default BoxItem;
