import React, {useCallback, useEffect, useState} from "react";
import {message, Modal, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import PayCard from "./PayCard";
import qImg from "../../../assets/img/game/ic_q.png";
import closeimg from "../../../assets/img/game/closeimg.png";
import openingBox from "../../../assets/img/game/box.gif";
import openedBox from "../../../assets/img/game/box-open.png";
import "./blindBox.less";
import {useWeb3React} from "@web3-react/core";
import {useBindBox, usePxwToken, usePxwTokenLib, useSwapRoter} from "../../../hooks/useContract";
import {getUsdtBalanceNumber} from "../../../utils/formatBalance";
import {BigNumber} from "bignumber.js";
import {APPROVE_AMOUNT, BIND_BOX, DECIMAL_BNB, ETH} from "../../../constants";
import useInterval from "../../../hooks";
import useEthBalance from "../../../hooks/useEthBalance";
import useEthOpenBox from "../../../hooks/useEthOpenBox";
import useHandleTransactionReceipt from "../../../hooks/useHandleTransactionReceipt";
import useStake from "../../../hooks/useStake";

const Blindbox = (props) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const pxwTokenLib = usePxwTokenLib();
  const pxwToken = usePxwToken();
  const bindBox = useBindBox();
  const ethBalance = useEthBalance(account);
  const { doOpenBox } = useEthOpenBox();


  useEffect(() => {}, []);
  const [isModalFarms, setIsModalFarms] = useState(0);//0隐藏，1：加载中，2：成功
  const [openTokenAmount, setOpenTokenAmount] = useState(0);
  // 提示文字
  const tooltipText = t("tooltipText");
  const handleCancelFarms = () => {
    setIsModalFarms(0);
  };

  const [openBoxInfoList, setOpenBoxInfoList] = useState([]);

  const [pxwBalance,setPxwBalance] = useState(0);
  const [pxwAllowance,setPxwAllowance] = useState(0);

  const fetchLastTime2 = useCallback(async () => {
    try{
      if(!account)return;
      // console.info('account=' + account);
      // console.info(boxList);
      const pxwAllowance = getUsdtBalanceNumber(new BigNumber((await pxwTokenLib.allowance(account, BIND_BOX)).toString()));
      // console.info('pxwAllowance=' + pxwAllowance);
      setPxwAllowance(pxwAllowance);
      const pxwBalance = getUsdtBalanceNumber(new BigNumber((await pxwTokenLib.balanceOf(account)).toString()));
      setPxwBalance(pxwBalance);
      const boxInfo = await bindBox.getBoxInfo();
      // console.info(boxInfo);

      let boxList = [];
      for (let i = 0; i < boxInfo.length; i++) {
        const oneBox = boxInfo[i];
        const isEth = oneBox[0].toLowerCase() === ETH;
        const oneBoxInfo = {
          id:i,
          token: oneBox[0].toLowerCase(), //抽奖的币种
          rewardToken: oneBox[1].toLowerCase(), //奖励代币
          amount: getUsdtBalanceNumber(new BigNumber(oneBox[2].toString())),//抽奖数量
          result1: getUsdtBalanceNumber(new BigNumber(oneBox[3].toString())),
          result2: getUsdtBalanceNumber(new BigNumber(oneBox[4].toString())),
          result3: getUsdtBalanceNumber(new BigNumber(oneBox[5].toString())),
          result4: getUsdtBalanceNumber(new BigNumber(oneBox[6].toString())),
          result5: getUsdtBalanceNumber(new BigNumber(oneBox[7].toString())),
          participatePeople: parseInt(oneBox[8].toString()), //参与人数
          eth: isEth
        };
        if(!isEth){
          boxList.push(oneBoxInfo);
        }
      }
      setOpenBoxInfoList(boxList);
    }catch (e) {
      console.error(e);
    }
  }, [account, pxwToken, pxwTokenLib, setPxwBalance, setPxwAllowance, BIND_BOX, ETH,
    setOpenBoxInfoList, bindBox,
  ]);
  useInterval(fetchLastTime2, 2000);

  const doOpenBpx = useCallback(async (boxId) => {
    if (!account) {
      return message.error("please connect the wollet first");
    }
    // 后续操作
    try {
      const boxInfo = openBoxInfoList[boxId];

      if(boxInfo.eth) {
        if (ethBalance < boxInfo.amount) {
          return message.error(t("your BNB token balance is not enough"));
        }
        console.info("amount=" + boxInfo.amount)
        const amountValue = new BigNumber((boxInfo.amount).toString()).multipliedBy(DECIMAL_BNB).toString();

        console.info("amountValue=" + amountValue)
        message.success(t("sending"));
        await doOpenBox(amountValue, boxId, (state, value)=>{
          // message.success(state + '====>' + value);
          setIsModalFarms(state);
          if(state === 2){
            setOpenTokenAmount(value);
          }
        });
      }else{
        if (pxwBalance < boxInfo.amount) {
          return message.error(t("your Pxw token balance is not enough"));
        }

        if (pxwAllowance < boxInfo.amount) {
          message.success(t("sending"));
          pxwToken.approve(BIND_BOX, APPROVE_AMOUNT).then(async (responseA) => {
            console.info(responseA);
            message.success(t('approve success'));
          }).catch((error) => {
            message.error(t('error'));
            console.log(error);
          });
          return;
        }
        message.success(t("sending"));
        await doOpenBox('0', boxId, (state, value)=>{
          // message.success(state + '====>' + value);
          setIsModalFarms(state);
          if(state === 2){
            setOpenTokenAmount(value);
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [account, bindBox, openBoxInfoList, pxwBalance, APPROVE_AMOUNT, DECIMAL_BNB, setIsModalFarms, setOpenTokenAmount]);


  const onClickModalOpenBox = (boxId) => {
    console.info('parentsss onClickModalFarms=' + boxId);
    doOpenBpx(boxId);
  }

  return (
    <div className="boxBody">
      <div className="boxHeader">
        <div className="headerTitle">
          <p>{t("Welcome to the lottery")}</p>
          <Tooltip title={tooltipText}>
            <img className="headerQ" src={qImg} />
          </Tooltip>
        </div>
      </div>

      <div className="pay_card_warp">
        <div className="pay_card_content">
          {openBoxInfoList.map((item, index) => {
            return (
              <PayCard
                key={index}
                boxId={index}
                amount={item.amount}
                token={item.eth ? 'BNB' : 'PXW'}
                needApprove = {item.eth ? false : (pxwAllowance < item.amount)}
                result1={item.result1}
                result2={item.result2}
                result3={item.result3}
                result4={item.result4}
                result5={item.result5}
                participatePeople={item.participatePeople}
                data={item}
                onBoxClick={onClickModalOpenBox}
              />
            );
          })}
        </div>
      </div>

      {/* 弹窗 */}
      <Modal
        title={ isModalFarms === 2 ? 'Minted' : 'Openging'}
        visible={isModalFarms !== 0}
        onCancel={handleCancelFarms}
        closeIcon={<img src={closeimg} alt="" className="closeimg" />}
        centered={true}
        footer={null}
        className="farms_modal"
      >
        <div className="farms_modal_box">
          <img src={isModalFarms === 1? openingBox : openedBox} alt="" />
        </div>

        {
          isModalFarms === 2 ? <div className="open_box_amount">奖励数量: {openTokenAmount}</div> : <div/>
        }
      </Modal>
    </div>
  );
};
export default Blindbox;
