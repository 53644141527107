import React, {useCallback, useState} from "react";
import {message, Row,Button} from "antd";
import "./PayCard.less";
import {useTranslation} from "react-i18next";

import {useWeb3React} from "@web3-react/core";
import useEthBalance from "../../../hooks/useEthBalance";
import BoxItem from "./BoxItem";

interface CardProps {
  data?: object;
  boxId?: String;
  amount: string;
  token: string;
  needApprove: boolean;
  participatePeople?: String;
  onBoxClick?: (index: any) => void;

  result1: string;
  result2: string;
  result3: string;
  result4: string;
  result5: string;
}

const PayCard: React.FC<CardProps> = (
    {
        data,
        onBoxClick,
        boxId,
        amount,
        token,
        needApprove,
        participatePeople,
        result1,
        result2,
        result3,
        result4,
        result5,
      }
  ) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();


  const doOpenItemBox = () => {
    console.info(boxId);
    onBoxClick(boxId);
  }


  return (
      <div className="pay_card_box_pool">
        <div className="pay_card_inline">

          <div className="cardTitle">
            {t('Lottery',{coin: token, curr: amount + '' + token })}
          </div>

          <Row className="top_box">
            <BoxItem amount={result1}/>
          </Row>

          <Row className="paycard_imgbox spacex1">
            <BoxItem amount={result2}/>
            <BoxItem amount={result3}/>
          </Row>

          <Row className="paycard_imgbox spacex2">
            <BoxItem amount={result4}/>
            <BoxItem amount={result5}/>
          </Row>

          <div>参与人数: {participatePeople}</div>

          <div className="marginTop1">
            <Button className="comm_btn" onClick={doOpenItemBox}>
              {t(needApprove ? 'approve' : 'Start the lottery')}
            </Button>
          </div>

        </div>
      </div>
  );
};

export default PayCard;
