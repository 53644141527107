import React, { useState } from "react";
import styled from "styled-components";
import { ApolloProvider } from "react-apollo";
import { client } from "./apollo/client";
import { BrowserRouter, Switch } from "react-router-dom";
import { useGlobalChartData, useGlobalData } from "./contexts/GlobalData";
import Routes from "./routes";
import routesConfig from "./routes/config";
import { useLatestBlocks } from "./contexts/Application";
import "./App.css";
import Frame from "./components/Frame/index";

const AppWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;
const ContentWrapper = styled.div`
  // display: grid;
  // grid-template-columns: ${({ open }) =>
    open ? "0px 1fr 0px" : "0px 1fr 0"};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 220px 1fr;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    max-width: 100vw;
    overflow: hidden;
    grid-gap: 0;
  }
`;

const Right = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  z-index: 99;
  width: ${({ open }) => (open ? "220px" : "64px")};
  height: ${({ open }) => (open ? "fit-content" : "64px")};
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

const Center = styled.div`
  height: 100%;
  z-index: 9999;
  width: 100%;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;

/**
 * Wrap the component with the header and sidebar pinned tab
 */
// eslint-disable-next-line react/prop-types
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <>
      <ContentWrapper open={savedOpen}>
        {/* <SideNav /> */}
        <Center id="center">{children}</Center>
        {/* <Right open={savedOpen}>
          <PinnedData open={savedOpen} setSavedOpen={setSavedOpen} />
        </Right> */}
      </ContentWrapper>
    </>
  );
};

const BLOCK_DIFFERENCE_THRESHOLD = 30;

function App() {
  const [latestBlock, headBlock] = useLatestBlocks();

  return (
    <BrowserRouter>
      <Switch>
        <Frame>
          <Routes />
        </Frame>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
