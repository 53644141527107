import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const getUsdtBalanceNumber = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  const roundedValue = displayBalance.decimalPlaces(8, BigNumber.ROUND_DOWN).toFixed();
  return parseFloat(roundedValue);
}

export const getClassBalanceNumber = (balance: BigNumber, decimals = 6) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const getUsdtBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}


export const getDisplayBalance = (balance: BigNumber, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}


export const getMinBalance = (balance: BigNumber, decimals = 18, length=6) => {
  if(!decimals){decimals=18}
  let x = balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed(length).toString();
   if(x.indexOf(".")>=0){
     return x.substring(0, x.length - 1);
   }
  return x;
}


