import {useCallback} from 'react';
import {useWeb3React} from '@web3-react/core'
import {BIND_BOX} from "../constants";
import Web3 from 'web3';
import {ethers} from 'ethers';
import BIND_BOX_ABI from "../constants/abis/BindBox.json";
import {getUsdtBalanceNumber} from "../utils/formatBalance";
import {BigNumber} from "bignumber.js";

const useEthOpenBox = () => {
    const {library,account} = useWeb3React();
    const handleStake = useCallback(async (amountEth: string, boxId: string, callBack: Function) => {
            const web3 = new Web3(library.provider);
            const gasPrice = await web3.eth.getGasPrice();
            // @ts-ignore
            const bindBox = new web3.eth.Contract(BIND_BOX_ABI, BIND_BOX);

            await bindBox.methods
                .executeBox(boxId)
                .send({
                    maxFeePerGas: ethers.utils.parseUnits("3", "gwei"),
                    maxPriorityFeePerGas: gasPrice,
                    from: account,
                    value: amountEth
                })
                .on('receipt', function(receipt){
                    console.info('receipt===>');
                    console.log(receipt);
                    const isCoin = amountEth === '0';
                    const amountToken = isCoin ? receipt.events.Transfer[1].returnValues.value : receipt.events.Transfer.returnValues.value;
                    const amountValue = getUsdtBalanceNumber(new BigNumber(amountToken));
                    callBack(2, amountValue);
                })
                .on('transactionHash', async (hash) => {
                    console.info('hash===>' + hash);
                    callBack(1, 0);
                }).on('error', async (hash) => {
                    callBack(0, 0);
                });
            // console.info('receipt===>');
            // console.info(receipt);

        },
        [library, account, BIND_BOX],
    );
    return { doOpenBox: handleStake };
};
export default useEthOpenBox;



