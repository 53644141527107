import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import rule_left from "../../../assets/img/rule/rule_left.png";
import rule_right from "../../../assets/img/rule/rule_right.png";
import "./economic.less";
import {PXW} from "../../../constants";

const Economic = (props) => {
  const { t } = useTranslation();
  // 地址
  useEffect(() => {}, []);
  return (
    <div className="EconomicPage">
      <div className="economicpage_top">
        <div className="fomofi">
          <div className="title">PXW</div>
          <ul>
            <li>
              <span>{t("Total Supply")}：</span>
              <span>23,000,000</span>
            </li>
            <li>
              <span>Buys / Sells taxes：</span>
              <span>2%</span>
            </li>
            <li>
              <span>{t("Ownership")}：</span>
              <span>{t("Renounced")}</span>
            </li>
            <li>
              <span>{t("LP Status")}：</span>
              <span>{t("Burned")}</span>
            </li>
          </ul>
          <div className="address">
            <div className="text">{t("Contract address")}：</div>
            <div className="address_input">{PXW}</div>
          </div>
        </div>
        <div className="headArea">
          <div className="ruleHeader">
            <div className="headerTitle">
              <img className="headerQ" src={rule_left} />
              <p>{t("View Rules")}</p>
              <img className="headerQ" src={rule_right} />
            </div>
          </div>

          <div className="ruleContent">
            <div className="space title">{t("PXW Rules Declaration")}</div>

            <div className="space text">
              {t(
                "The token symbols $PXW (shortened as $PXW) and $PXWBuild (shortened as $build) are used in the following references"
              )}
            </div>

            <div className="space text">
              {t(
                "Convert your $PXW tokens into an equivalent amount of $BNB and mint $build tokens as proof"
              )}
            </div>

            <div className="space title">{t("Assumption")}</div>

            <div className="space text">
              {t(
                "PXWing 1000 $PXW tokens worth 1 $BNB will mint 1 $build token as your proof"
              )}
            </div>

            <div className="space text">
              {t(
                "Simultaneously, an additional 1 $build token will be minted and distributed to all $build holders [based on $build holdings]"
              )}
            </div>

            <div className="space text">
              {t(
                "If you are the first to PXW $PXW tokens worth 1 $BNB, you will receive 1 $build and 1 $build reflection, totaling 2 tokens"
              )}
            </div>

            <div className="space text">
              {t(
                "When the second PXW, with tokens worth 2 $BNB, occurs, the participant will receive 2 $build proofs and 1 $build reflection. You will also receive 1 $build (as you occupy 50% of the share)"
              )}
            </div>

            <div className="space text">{t("And so on")}</div>

            <div className="space title">-</div>

            <div className="space text">{t("Claim Rewards")}</div>

            <div className="space text">
              {t(
                "Reward calculation: Your holdings of $build - Your proof (build) = BNB reward you can claim"
              )}
            </div>

            <div className="space text">
              {t(
                "Assuming your initial PXW value was 1 $build (proof), when you accumulate 10 $build, you can claim a reward of 9 $BNB"
              )}
            </div>

            <div className="space text">
              {t(
                "Regardless of the quantity of your $build proofs, they will be reset to zero when you claim the reward"
              )}
            </div>

            <div className="space text">
              {t(
                "Don't claim rewards too easily; leave them for community development"
              )}
            </div>

            <div className="space title">-</div>

            <div className="space title">{t("Invite Rewards")}</div>

            <div className="space text">
              {t(
                "When a PXWer uses your link to PXW $PXW tokens, you can receive a 10% reward in $PXW tokens"
              )}
            </div>

            <div className="space text">
              {t(
                "If you were also invited, the person who invited you can also receive a 5% reward in $PXW tokens"
              )}
            </div>

            <div className="space text">
              {t(
                "The remaining $PXW tokens will be transferred to the black hole (0xdead) address, at least 85%"
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="EconomicTxtArea">
        <div className="EconomicTitle">{t("PXW Economic Modeling")}</div>
        <div className="EconomicContent">
          <div className="EconomicItem">
            <div className="title">{t("PXW title one")}</div>
            <ul className="desc">
              <li>{t("PXW text one one")}</li>
              <li>{t("PXW text one two")}</li>
              <li>{t("PXW text one therr")}</li>
              <li>{t("PXW text one four")}</li>
              <li>{t("PXW text one five")}</li>
              <li>{t("PXW text one six")}</li>
              <li>{t("ONE_WALLET_LINT")}</li>
            </ul>
          </div>
          <div className="EconomicItem">
            <div className="title">{t("PXW title two")}</div>
            <ul className="desc">
              <li>{t("PXW text two one")}</li>
              <li>{t("PXW text two two")}</li>
              <li>{t("PXW text two therr")}</li>
            </ul>
          </div>
          <div className="EconomicItem">
            <div className="title">{t("PXW title therr")}</div>
            <ul className="desc">
              <li>{t("PXW text therr one")}</li>
              <li>{t("PXW text therr two")}</li>
              <li>{t("PXW text therr therr")}</li>
            </ul>
          </div>
          <div className="EconomicItem">
            <div className="title">{t("PXW title four")}</div>
            <ul className="desc">
              <li>{t("PXW text four one")}</li>
              <li>{t("PXW text four two")}</li>
              <li>{t("PXW text four therr")}</li>
            </ul>
          </div>
          <div className="EconomicItem">
            <div className="title">{t("PXW title five")}</div>
            <ul className="desc">
              <li>{t("PXW text five one")}</li>
              <li>{t("PXW text five two")}</li>
            </ul>
          </div>
          <div className="EconomicItem">
            <div className="title">{t("PXW title six")}</div>
            <ul className="desc">
              <li>{t("PXW text six one")}</li>
              <li>{t("PXW text six two")}</li>
              <li>{t("PXW text six therr")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Economic;
