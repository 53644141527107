import {useCallback} from 'react';
import {useWeb3React} from '@web3-react/core'
import {SWAP_ROUTER} from "../constants";
import Web3 from 'web3';
import { ethers } from 'ethers';
import ROUTER_ABI from '../constants/abis/Swap.json';

const useEthBySwap = () => {
    const {library,account} = useWeb3React();
    const handleStake = useCallback(async (amountIn: string, amountOutMin: string, path: any, deadline: string) => {
            const web3 = new Web3(library.provider);
            const gasPrice = await web3.eth.getGasPrice();
            // @ts-ignore
            const swapRoter = new web3.eth.Contract(ROUTER_ABI, SWAP_ROUTER)
            swapRoter.methods
                .swapExactETHForTokensSupportingFeeOnTransferTokens(amountOutMin, path, account, deadline)
                .send({
                    maxFeePerGas: ethers.utils.parseUnits("3", "gwei"),
                    maxPriorityFeePerGas: gasPrice,
                    from: account,
                    value: amountIn
                })
                .on('transactionHash', (hash) => {
                    console.info('hash===>' + hash);
                })
        },
        [library, account],
    );
    return { doBuySwap: handleStake };
};
export default useEthBySwap;



