import {BigNumber} from "bignumber.js";
import {ethers} from "ethers";

export const NetworkContextName = "NETWORK";

export const SWAP_FACTORY = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
export const SWAP_ROUTER = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
export const WBNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const BIND_BOX = "0x99b2d996Ea9ff4f9D3775c17Ab32C56029d6476A";
export const AIRDROP = "0x54F6B05156837CedD58D60dE692AeF39B5Ca6a1D";
export const PXW = "0x8C6cD42083c7B19f25d42c7788942986b96A0380";
export const PXWBUILD = "0x86306109F82d9F8400b2ea967bb51137505420c1";

export const ETH = "0x0000000000000000000000000000000000000000";
export const APPROVE_AMOUNT = ethers.constants.MaxUint256;
export const SERVER_URL = "http://pxwchain.live/";

export const DECIMAL_BNB = new BigNumber(1e18);

export const FACTORY_ADDRESS = "5";

export const BUNDLE_ID = "1";

export const timeframeOptions = {
  WEEK: "1 week",
  MONTH: "1 month",
  ALL_TIME: "All time",
};

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  "https://gateway.ipfs.io/ipns/tokens.uniswap.org",
  "https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json",
];

