import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import against from "../../utils/against";
import { Dropdown, Modal, Drawer } from "antd";
import { injected } from "../../connectors";

import { setupNetwork } from "../../utils/wallet";
import { useEagerConnect, useInactiveListener } from "../../hooks";
import { MenuOutlined } from "@ant-design/icons";

import blindBox from "../../assets/img/scy/blind_box.png";
import home from "../../assets/img/scy/icon-home.png";
import pointer from "../../assets/img/scy/icon-pointer-leftright.png";
import schedule from "../../assets/img/scy/icon-schedule-square.png";
import bnb from "../../assets/img/scy/bnb.png";
import metamask from "../../assets/img/metamask.png";
import world from "../../assets/img/scy/icon-world.png";

import closeimg from "../../assets/wallet/closeimg.png";
import mathwallet from "../../assets/wallet/mathwallet.png";
import trustwallet from "../../assets/wallet/trustwallet.png";
import walletconnect from "../../assets/wallet/walletconnect.png";

import "./header.less";
const Header = (props) => {
  const { t, i18n } = useTranslation();
  const { account, connector, activate } = useWeb3React();

  const [activatingConnector, setActivatingConnector] = React.useState();

  const [open, setOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setupNetwork().then();
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  const [isLinkWalletModal, setIsLinkWalletModal] = useState(false);

  useEffect(() => {}, []);
  const routerList = [
    {
      path: "/",
      img: home,
      text: t("HOME"),
    },
    {
      path: "/swap",
      img: pointer,
      text: t("exchange"),
    },
    {
      path: "/blindbox",
      img: blindBox,
      text: t("Blind box"),
    },
    {
      path: "/economic",
      img: schedule,
      text: t("Economic map planning"),
    },
  ];

  // 选中的语言
  const [activeLang, setActiveLang] = useState(
    localStorage.getItem("lang") || "cn"
  );
  // 是否显示移动端
  // 是否显示移动端
  const getLangName = () => {
    if (activeLang === "cn") return "中文";
    return "English";
  };

  const handleChangeLang = (langCode) => {
    setActiveLang(langCode);
    i18n.changeLanguage(langCode);
    localStorage.setItem("lang", langCode);
    // setShowDropdown(false);
    // setOpen(false);
  };

  const overlay = (
    <div className="header_right_list">
      <div className="item" onClick={() => handleChangeLang("en")}>
        <span>English</span>
      </div>
      <div className="item" onClick={() => handleChangeLang("cn")}>
        <span>中文</span>
      </div>
    </div>
  );
  const showLinkWalletModal = () => {
    setIsLinkWalletModal(true);
  };

  const cancelLinkWalletModal = () => {
    setIsLinkWalletModal(false);
  };
  const handleDropdownClick = () => {
    setShowDropdown(false);
  };

  // 链接钱包
  const linkWallet = () => {
    cancelLinkWalletModal();
    activate(injected, undefined, true).catch((error) => {
      if (error instanceof UnsupportedChainIdError) {
        activate(connector); // a little janky...can't use setError because the connector isn't set
      }
    });
  };

  return (
    <div className="header">
      <div className="header_con">
        <div className="header_left">
          {routerList.map((item, i) => {
            return (
              <NavLink to={item.path} key={i} className="header_router">
                <img src={item.img} alt="" />
                <div className="text">{item.text}</div>
              </NavLink>
            );
          })}
        </div>
        <div className="header_left_m">
          <MenuOutlined onClick={() => setOpen(!open)} />
        </div>
        <div className="header_right">
          <div className="connected">
            <img src={bnb} alt="" />
            <div className="text">BSC</div>
          </div>
          <Dropdown
            overlay={overlay}
            placement="bottomRight"
            className="lang_drop"
          >
            <div className="lang">
              <img src={world} alt="" />
              <div className="text">{t("Choose language")}</div>
            </div>
          </Dropdown>
          {account ? (
            <div className="link_wallet">
              <span>{against(account)}</span>
            </div>
          ) : (
            <div className="link_wallet" onClick={showLinkWalletModal}>
              <span>{t("CONNECT")}</span>
            </div>
          )}
        </div>
      </div>

      {/* 链接钱包弹窗 */}

      <Modal
        title="Connect to wallet"
        visible={isLinkWalletModal}
        onCancel={cancelLinkWalletModal}
        closeIcon={<img src={closeimg} alt="" className="closeimg" />}
        centered={true}
        footer={null}
        className="link_wallet_dialog"
      >
        <div className="farms_modal_box" onClick={linkWallet}>
          <span>Metamask</span>
          <img src={metamask} alt="" />
        </div>

        {/* <div className='farms_modal_box' onClick={linkWallet}>
          <span>Math Wallet</span>
          <img src={mathwallet} alt="" />
        </div>
        <div className='farms_modal_box' onClick={linkWallet}>
          <span>TrustWallet</span>
          <img src={trustwallet} alt="" />
        </div>
        <div className='farms_modal_box' onClick={linkWallet}>
          <span>WalletConnect</span>
          <img src={walletconnect} alt="" />
        </div>*/}
      </Modal>

      <Drawer
        placement="top"
        closable={false}
        onClose={onClose}
        visible={open}
        className="menu_drawer"
      >
        <div className="menu">
          {routerList.map((item, i) => {
            return (
              <NavLink
                to={item.path}
                key={i}
                className="menu_router"
                onClick={() => setOpen(false)}
              >
                <img src={item.img} alt="" />
                <div className="text">{item.text}</div>
              </NavLink>
            );
          })}
          <Dropdown
            overlay={overlay}
            placement="bottomRight"
            className="lang_drop"
          >
            <div className="lang">
              <img src={world} alt="" />
              <div className="text">{t("Choose language")}</div>
            </div>
          </Dropdown>
        </div>
      </Drawer>
    </div>
  );
};
export default Header;
